.doctor-chat {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: white;
}

.doctor-chat main {
    height: 100%;
    padding: 0 10px;
    margin: 0 0 60px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.doctor-chat main::-webkit-scrollbar {
    width: 0.25rem;
}

.doctor-chat main::-webkit-scrollbar-track {
    background: var(--gray);
}

.doctor-chat main::-webkit-scrollbar-thumb {
  background: var(--gray-dark);
}

.doctor-chat form {
    height: 60px;
    position: absolute;
    bottom: 0;
    /* background-color: rgb(24, 23, 23); */
    width: 100%;
    display: flex;
    font-size: 14px;
    border-top: 1px solid var(--gray-dark);
    align-items: center;
    padding: 3px 10px;
}

.doctor-chat .input-wrapper {
    display: flex;
    flex: 1;
}

// .doctor-chat .input-wrapper input {
//     flex: 1;
//     margin-right: 15px
// }

// .doctor-chat .input-wrapper button {
//     flex: 0;
// }

.doctor-chat .message {
    display: flex;    
    width : 100%;
    align-items: flex-end;
    margin-bottom: 12px;

    .message-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 2px 8px;
        object-fit: cover;
    }

    .message-box {
        display: flex;
        flex-direction: column;
        max-width: 70%;
        .message-content {
            border-radius: 10px;
            overflow: hidden;
            color: white;

            p {
                padding: 5px 15px;
                line-height: 24px;
                text-align: left;
                margin-bottom: 8px;
            }

            .message-image > img {
                cursor: pointer;
                max-width: 300px;
            }

            .message-file {
                cursor: pointer;
                padding: 10px 25px;
            }
        }
        

        .message-time {
            margin-top: 1rem;
            font-size: 10px;
            font-weight: 400;
        }
    }

    &.sent {
        flex-direction: row-reverse;

        .message-content {        
            color: white;
            background: var(--primary);
        }

        .message-time {
            align-self: flex-end;
        }
    }

    &.received {
        .message-content {
            background: var(--gray);
            color: var(--first);
        }
    }
}

.doctor-chat .message-system {
    display: flex;    
    flex-direction: column;
    width : 100%;
    align-items: center;
    margin-bottom: 12px;

    .message-system-content {
        padding: 5px 15px;
        border-radius: 10px;
        background: var(--success);
        color: var(--white);
        text-align: center;

        p {
            margin-bottom: 0;
        }

        span {
            font-size: 10px;
        }
    }

    
}



